export class ViveCalc {

	constructor() {
	};

	public foliar_rates       : Array<number> = [7.6, 13.6, 19.5];
	public foliar_fruit_rates : Array<number> = [7.6, 13.6, 19.5];
	public foliar_V4_V8       : Array<number> = [7.6];
	public row_spacing        : Array<number> = [22,24,26,28,30,32,34,36,38,40];
	public in_furrow_rates    : Array<number> = [0.5, 1];
	public in_furrow_ratesNames = ["Low 0.5 fl.oz./1000 row ft" , "High 1.0 fl.oz./1000 row ft"];
	public bi_in_furrow_rates : Array<number> = [0.17, 0.34, 0.42];
	public bi_corn_in_furrow_rates : Array<number> = [0.17, 0.34, 0.42 , 0.67 , 0.84];
	public bi_corn_in_furrow_ratesNames = ["Low 0.17 fl. oz./1000 row ft", "Medium Low 0.34 fl. oz./1000 row ft",  "Medium 0.42 fl.oz./1000 row ft", "Medium High 0.67 fl.oz./1000 row ft" , "High 0.84 fl.oz./1000 row ft" ]
	public layby_rates        : Array<number> = [11, 16.6, 22.1];
	public pre_rates          : Array<number> = [2.9];
	public ppi_rates          : Array<number> = [3.5, 4.6];
	public bi_foliar_rates    : Array<number> = [2.4, 4.9, 7.4];
	public units              : string        = "fl. oz./ac";
	public in_furrow_units    : string        = "fl. oz./1000 row ft";
	public inFurrowRateTable  = {
            '22' : {
                '0.5'  : 11.9,
                '1'    : 18.9,
                '0.17' : 4.0,
                '0.34' : 8.1,
                '0.42' : 10.0,
                '0.67' : 14.6,
                '0.84' : 14.6
            },
            '24' : {
                '0.5'  : 10.9,
                '1'    : 18.9,
                '0.17' : 3.7,
                '0.34' : 7.4,
                '0.42' : 9.1,
                '0.67' : 14.6,
                '0.84' : 14.6
            },
            '26' : {
                '0.5'  : 10.1,
                '1'    : 18.9,
                '0.17' : 3.4,
                '0.34' : 6.8,
                '0.42' : 8.4,
                '0.67' : 13.5,
                '0.84' : 14.6
            },
            '28' : {
                '0.5'  : 9.3,
                '1'    : 18.7,
                '0.17' : 3.2,
                '0.34' : 6.3,
                '0.42' : 7.8,
                '0.67' : 12.5,
                '0.84' : 14.6
            },
            '30' : {
                '0.5'  : 8.7,
                '1'    : 17.4,
                '0.17' : 3.0,
                '0.34' : 5.9,
                '0.42' : 7.3,
                '0.67' : 11.7,
                '0.84' : 14.6
            },
            '32' : {
                '0.5'  : 8.2,
                '1'    : 16.3,
                '0.17' : 2.8,
                '0.34' : 5.6,
                '0.42' : 6.9,
                '0.67' : 10.9,
                '0.84' : 13.7
            },
            '34' : {
                '0.5'  : 7.7,
                '1'    : 15.4,
                '0.17' : 2.6,
                '0.34' : 5.2,
                '0.42' : 6.5,
                '0.67' : 10.3,
                '0.84' : 12.9
            },
            '36' : {
                '0.5'  : 7.3 ,
                '1'    : 14.5,
                '0.17' : 2.5,
                '0.34' : 4.9,
                '0.42' : 6.1,
                '0.67' : 9.7,
                '0.84' : 12.2
            },
            '38' : {
                '0.5'  : 6.9,
                '1'    : 13.8,
                '0.17' : 2.3,
                '0.34' : 4.7,
                '0.42' : 5.8,
                '0.67' : 9.2,
                '0.84' : 11.6
            },
            '40' : {
                '0.5'  : 6.5,
                '1'    : 13.1,
                '0.17' : 2.2,
                '0.34' : 4.4,
                '0.42' : 5.5,
                '0.67' : 8.8,
                '0.84' : 11.0
            }
        };
	//The lookup table for the calculator app
	public table : any = {
		AZteroid : {
			corn        : {
				name : "Corn",
				methods : {
					foliar       : {
						name  : "Foliar",
						rates : this.foliar_rates
					},
					foliar_V4_V8 :{
						name  : "Foliar V4-V8",
						rates : this.foliar_V4_V8
					},
					in_furrow    : {
						name : "In-furrow"
					}
				}

			},
			soybeans : {
				name    : "Soybeans",
				methods :{
					foliar_fruit : {
						name  : "Foliar/fruit",
						rates : this.foliar_rates
					},
					in_furrow    : {
						name : "In-furrow"
					}
				}
			},
			sugar_beets : {
				name    : "Sugar Beets",
				methods : {
					foliar    : {
						name  : "Foliar",
						rates : this.foliar_rates
					},
					in_furrow : {
						name : "In-furrow"
					}
				}
			},
			potatoes : {
				name    : "Potatoes",
				methods : {
					foliar : {
						name  : "Foliar",
						rates : this.foliar_rates
					},
					in_furrow : {
						name : "In-furrow"
					}
				}
			}
		},
		Bifender : {
			corn     : {
				name: "Corn",
				methods : {
					pre : {
						name  : "PRE",
						rates : this.pre_rates
					},
					ppi : {
						name  : "PPI",
						rates : this.ppi_rates
					},
					foliar : {
						name  : 'Foliar',
						rates : this.bi_foliar_rates
					},
					in_furrow : {
						name : "In-furrow"
					}
				}

			},
			soybeans : {
				name    : "Soybeans",
				methods : {
					pre : {
						name  : "PRE",
						rates : this.pre_rates
					},
					ppi : {
						name  : "PPI",
						rates : this.ppi_rates
					},
					foliar : {
						name  : 'Foliar',
						rates : this.bi_foliar_rates
					},
					in_furrow : {
						name : "In-furrow"
					}
				} 
			},
			potatoes : {
				name : "Potatoes",
				methods : {
					in_furrow_layby : {
						name  : "In-furrow/lay-by",
						rates : this.layby_rates
					}
				}
			}
		}
	};

	/**
     * Gets the available products
     * @returns {Array<String>} Array of available products
	 */
	public getProducts() : Array<string> {
		return Object.keys(this.table);
	};

	/**
     * Gets the crops available for a selected product
	 * @param {String} The selected product
     * @returns {Array<String>} Array of available crops
	 */
	public getCrops( product : string ) : any {
		var crops : any = [];
	 	for (var cropKey in this.table[product] ){
	 		var cropName : string = this.table[product][cropKey].name;
	 		crops.push({key : cropKey, name : cropName })
	 	}
 		return crops;
 	}

 	/**
     * Gets the application methods available for a selected product
	 * @param product {String} The selected product
	 * @param crop {String} product The selected crop
     * @returns {Array<String>} Array of available crops
	 */
 	public getMethods( product : string, crop : string ) : any {
		var methods : any = [];
	 	for (var methodKey in this.table[product][crop].methods ){
	 		var methodName : string = this.table[product][crop].methods[methodKey].name;
	 		methods.push({key : methodKey, name : methodName })
	 	}
 		return methods;
 	}

 	public getSpacings() : Array<number>{
 		return this.row_spacing;
 	}

 	public getRates( product : string, crop : string, method : string ) : any {
 		return this.table[product][crop].methods[method].rates;
 	}

 	public getInFurrowRates(method : string, crop : any) : any {
 		if(method == "AZteroid"){
 			return this.in_furrow_rates;
 		}
 		else{
 			if (crop == "corn"){
 				return this.bi_corn_in_furrow_rates;
 			}
 			return this.bi_in_furrow_rates;
 		}
 	}
 	public getInFurrowRateNames(method : string, crop : any) : any{
 		if(method == "AZteroid"){
 			return this.in_furrow_ratesNames;
 		}
 		else{
 			return this.bi_corn_in_furrow_ratesNames;
 		}

 	}

 	public getUnits(method : string){
 		if(method != "in_furrow"){
 			return this.units;
 		}
 		else{
 			return this.in_furrow_units;
 		}
 	}

 	public getInfurrowRate(spacing : number, rate : number) : number {
 		return this.inFurrowRateTable[spacing][rate];

 	}

}
