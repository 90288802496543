import { Component } from '@angular/core';
import { Nav, ModalController } from 'ionic-angular';
import { ViveCalc } from '../../calculator';
import { TermsPopover } from '../terms/terms';
import {RoiPage} from '../roi/roi';

@Component({
  templateUrl: 'home.html'
})
export class HomePage {
	public products : Array<string>;
	public product  : string;
	public crops    : Object;
	public crop     : string;
	public methods  : Object;
	public method   : string;
	public spacings : Array<number>;
	public spacing  : number;
	public rates;
	public rateNames;
	public rate;
	public units    : string; 
	public calculatedRate;
	public fieldSize;
	public productVolume;
	public vCalc;
	public volume;
	public volumeFormatted;

	constructor(public modalCtrl : ModalController, public nav : Nav) {
		this.vCalc = new ViveCalc;
		this.products = this.vCalc.getProducts();
	}
	productSelect(){
		console.log(this.product)
		this.crops = this.vCalc.getCrops(this.product);
		this.crop = undefined;
		this.method = undefined;
		this.methods  =undefined;
		this.spacing = undefined;
		this.spacings = undefined;
		this.rate = undefined;
		this.rateNames = undefined;
		this.rates = undefined;
		this.calculatedRate = undefined;
		this.volumeFormatted = undefined;


	}
	cropSelect(){
		console.log(this.crop);
		this.methods = this.vCalc.getMethods(this.product, this.crop);
		this.method = undefined;
		this.spacing = undefined;
		this.spacings = undefined;
		this.rate = undefined;
		this.rateNames = undefined;
		this.rates = undefined;
		this.calculatedRate = undefined;
		this.volumeFormatted = undefined;

	}

	methodSelect(){
		console.log(this.method);
		this.units = this.vCalc.getUnits(this.method);
		if(this.method != "in_furrow"){
			this.rates = this.vCalc.getRates(this.product, this.crop, this.method);
			this.rateNames = this.rates.map(function(e){return e + " " + this.units}, this);
			this.spacings = undefined;
		}
		else{
			this.spacings = this.vCalc.getSpacings();
			this.rates = undefined;
		}

		this.spacing = undefined;
		this.rate = undefined;
		this.calculatedRate = undefined;
		this.volumeFormatted = undefined;

	}
	spacingSelect(){
		console.log(this.spacing);
		console.log(this.crop);
		this.rates = this.vCalc.getInFurrowRates(this.product, this.crop);
		this.rateNames = this.vCalc.getInFurrowRateNames(this.product, this.crop);
		if (this.rateNames == null){
			this.rateNames = this.rates;
		}
		this.rate = undefined;
		this.calculatedRate = undefined;
		this.volumeFormatted = undefined;
	}

	rateSelect(){
		console.log(this.rate)
		if(this.method != "in_furrow"){
			this.calculatedRate = this.rate;
		}
		else{
			this.calculatedRate = this.vCalc.getInfurrowRate(this.spacing, this.rate);
		}
	}

	fieldSet(){
		console.log(this.fieldSize);
	}

	showTerms(){
		let popover = this.modalCtrl.create(TermsPopover);
    	popover.present();

	}

	calculateVolume(){
		this.volume = ((this.calculatedRate * this.fieldSize) / 128);
		this.volumeFormatted = this.formatVolume(this.volume);		
	}
	reset(){
		this.product         = undefined;
		this.crops           = undefined;
		this.crop            = undefined;
		this.methods         = undefined;
		this.method          = undefined;
		this.spacings        = undefined;
		this.spacing         = undefined;
		this.rates           = undefined;
		this.rate            = undefined;
		this.calculatedRate  = undefined;
		this.fieldSize       = undefined;
		this.productVolume   = undefined;
		this.volume          = undefined;
		this.volumeFormatted = undefined;
		this.rateNames       = undefined;
	}
	openRoi() {
    	this.nav.setRoot(RoiPage, {
    		product        : this.product,
    		fieldSize      : this.fieldSize,
    		volume         : this.volume,
    		volumeFormatted: this.volumeFormatted,
    		calculatedRate : this.calculatedRate,
    		crop           : this.crop

    	});
  	}
  	numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	numberTo2Decimals(x){
		return x.toFixed(2);
	}
	numberTo3Decimals(x){
		return x.toFixed(3);
	}

	formatVolume(x){
		return this.numberWithCommas(this.numberTo3Decimals(x));
	}


}


