import { Component } from '@angular/core';
import { NavController ,Nav , NavParams, ModalController } from 'ionic-angular';
import { HomePage } from '../home/home';
import { TermsPopover } from '../terms/terms';

/*
  Generated class for the Roi page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  selector: 'page-roi',
  templateUrl: 'roi.html'
})
export class RoiPage {

	public product;
	public fieldSize;
  public productVolume;
  public productVolumeFormatted;
  public increase;
  public costPerAcre;
  public costPerAcreFormatted;
  public netProfit;
  public netProfitFormatted;
  public profit;
  public profitFormatted;
  public productCostPerGallon;
  public commodityPrice;
  public calculatedRate;
  public crop;
  public unitMap = {
    corn        : "bu/ac",
    soybeans    : "bu/ac",
    sugar_beets : "tons/ac",
    potatoes    : "cwt/ac"
  }
  public cropUnits;
  	constructor(public modalCtrl : ModalController, public navCtrl: NavController, public nav: Nav, public navParams: NavParams) {
      //Grabs all data from the home page
  		this.product = navParams.get("product");
  		//this.fieldSize = navParams.get("fieldSize");
      //this.productVolume = navParams.get("volume");
      //this.productVolumeFormatted = navParams.get("volumeFormatted")
      //this.calculatedRate = navParams.get("calculatedRate");
      this.crop = navParams.get("crop");
      this.cropUnits = this.unitMap[this.crop];
      console.log(this.cropUnits)

  	}

    fieldChange(){
      this.calculateNetProfit();
      this.calculateCostPerAcre();
    }

    calculateVolume(){
      if (this.calculatedRate && this.fieldSize){
        this.productVolume = (this.calculatedRate * this.fieldSize)/128;
        this.productVolumeFormatted = this.numberTo2Decimals(this.productVolume);
      }
      this.calculateNetProfit();
      this.calculateCostPerAcre();
    }

   calculateNetProfit () {
     if (this.costPerAcre && this.increase){
       this.netProfit = (this.commodityPrice * this.increase ) - this.costPerAcre;
       this.netProfitFormatted = this.formatPrice(this.netProfit)
       if(this.fieldSize){
         this.profit = this.netProfit * this.fieldSize;
         this.profitFormatted = this.formatPrice(this.profit);
       }
     }

    }

    calculateCostPerAcre (){
      if (this.productCostPerGallon && this.calculatedRate ){
        this.costPerAcre  = (this.productCostPerGallon / 128 ) * this.calculatedRate;
        this.costPerAcreFormatted = this.formatPrice(this.costPerAcre);
        if (this.netProfit && this.fieldSize){
          this.profit = this.netProfit * this.fieldSize;
          this.profitFormatted = this.formatPrice(this.profit);
        }
      }
    }

  openHome() {
    this.nav.setRoot(HomePage);
  }
  showTerms(){
    let popover = this.modalCtrl.create(TermsPopover);
      popover.present();

  }

  reset(){
    this.product = undefined;
    this.fieldSize = undefined;
    this.productVolume = undefined;
    this.productVolumeFormatted = undefined;
    this.increase = undefined;
    this.costPerAcre = undefined;
    this.costPerAcreFormatted = undefined;
    this.netProfit = undefined;
    this.netProfitFormatted = undefined;
    this.profit = undefined;
    this.profitFormatted = undefined;
    this.productCostPerGallon = undefined;
    this.commodityPrice = undefined;
    this.calculatedRate = undefined;
    this.crop = undefined;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  numberTo2Decimals(x){
    return x.toFixed(2);
  }
  numberTo3Decimals(x){
    return x.toFixed(3);
  }
  formatPrice(x){
    return this.numberWithCommas(this.numberTo2Decimals(x));
  }


}
