import { Component } from '@angular/core';
import { ViewController } from 'ionic-angular';

@Component({
  templateUrl: 'terms.html'
})
export class TermsPopover {
	constructor(public viewCtrl: ViewController){

	}
	dismiss(){
		this.viewCtrl.dismiss();
	}
}